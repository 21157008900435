<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
      <c-table
        ref="grid1"
        title="평가항목"
        tableId="grid1"
        :columnSetting="false"
        :isFullScreen="false"
        :usePaging="false"
        :filtering="false"
        :hideBottom="true"
        :columns="grid1.columns"
        :data="grid1.data"
        @rowClick="rowClick"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
      <c-table
        ref="grid2"
        title="평가항목별 세부항목"
        tableId="grid2"
        :columnSetting="false"
        :isFullScreen="false"
        :usePaging="false"
        :filtering="false"
        :hideBottom="true"
        :merge="grid2.merge"
        :columns="grid2.columns"
        :data="grid2.data"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-plan-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      grid1: {
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '평가항목',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        merge: [
          { index: 0, colName: 'selfInspectionItemTypeId' },
        ],
        columns: [
          {
            name: 'itemTypeName',
            field: 'itemTypeName',
            label: '구분',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'No.',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '세부항목',
            align: 'left',
            type: 'html',
            sortable: false,
          },
        ],
        data: [],
      },
      list1Url: '',
      list2Url: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.list1Url = selectConfig.sop.pca.item.revs.url;
      this.list2Url = selectConfig.sop.pca.items.mst.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.list1Url;
      this.$http.type = 'GET';
      this.$http.param = {sysRevision: this.popupParam.sysRevision};
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
    rowClick(row) {
      this.$http.url = this.list2Url;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionItemMstId: row.selfInspectionItemMstId
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
  }
};
</script>
